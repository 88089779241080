import React from 'react';
import HeroBanner from './heroBanners/HeroBanner';
import Carousel from './heroBanners/Carousel';
import StandoutHero from './heroBanners/StandoutHero';
import DefaultBanner from './heroBanners/DefaultBanner';
import DonationBanner from './heroBanners/DonationBanner';

const BannerContentLoop = ({ pageTitle, mainImage, contentArray }) => (
  <>
    {contentArray && contentArray.length !== 0 ? (
      contentArray.map((content) => (
        <section key={content._key}>
          {content._type === 'heroBanner' && (
            <HeroBanner mainImage={mainImage} content={content} />
          )}
          {content._type === 'videoBanner' && (
            <HeroBanner mainImage={mainImage} content={content} />
          )}
          {content._type === 'carousel' && (
            <Carousel mainImage={mainImage} content={content} />
          )}
          {content._type === 'standoutHero' && (
            <StandoutHero mainImage={mainImage} content={content} />
          )}
          {content._type === 'donationBanner' && (
            <DonationBanner mainImage={mainImage} content={content} />
          )}
        </section>
      ))
    ) : (
      <section>
        <DefaultBanner pageTitle={pageTitle} mainImage={mainImage} />
      </section>
    )}
  </>
);

export default BannerContentLoop;
