import React from 'react';
import styled from 'styled-components';
import BackgroundImage, { SBackgroundImage } from '../helpers/BackgroundImage';
import HeadingContent, {
  HeadingContainer,
} from '../reusableObjects/HeadingContent';
import { colors, breakpoints } from '../../styles/utilities/settings';

const DefaultBanner = ({ pageTitle, mainImage }) => (
  <SDefaultBanner>
    <HeadingContent hero content={{ heading: pageTitle }} />
    <BackgroundImage src={mainImage} />
  </SDefaultBanner>
);

export default DefaultBanner;

const SDefaultBanner = styled.div`
  color: ${colors.blue};
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    display: block;
    position: absolute;
    bottom: -1px;
    left: 0;
    height: 101%;
    width: 100%;
    z-index: 2;
    background: ${colors.blueGradient};

    @media screen and (max-width: ${breakpoints.ipadPort - 1}px) {
      background: ${colors.blueGradientMobile};
    }
  }

  ${SBackgroundImage} {
    position: absolute;
    top: 0;
    right: 0;

    @media screen and (min-width: ${breakpoints.ipadPort}px) {
      height: 420px;
      width: 1000px;
      z-index: 1;
    }

    @media screen and (max-width: ${breakpoints.ipadPort - 1}px) {
      height: 100%;
      width: 100%;
    }

    &:before {
      @media screen and (min-width: ${breakpoints.ipadPort}px) {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background: linear-gradient(
          99deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(255, 255, 255, 0.8) 20%,
          rgba(255, 255, 255, 0) 50%,
          rgba(255, 255, 255, 0) 100%
        );
      }
    }
  }

  ${HeadingContainer} {
    padding: 180px 50px;
    max-width: 1500px;
    margin: 0 auto;
    position: relative;
    z-index: 3;

    @media screen and (max-width: ${breakpoints.ipadPort - 1}px) {
      padding: 50px;
      max-width: none;
    }

    h2,
    h1 {
      margin: 0;
    }
  }
`;
