import React from 'react';
import refID from '../../js/refID';

const { GATSBY_SANITY_ID } = process.env;
const { GATSBY_SANITY_DATASET } = process.env;

const Video = ({ src }) => (
  <video playsInline autoPlay muted loop>
    <source
      src={`https://cdn.sanity.io/files/${GATSBY_SANITY_ID}/${GATSBY_SANITY_DATASET}/${refID(
        src.asset._ref
      )}.mp4`}
      type="video/mp4"
    />
  </video>
);

export default Video;
