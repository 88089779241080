import React from 'react';
import styled from 'styled-components';
import DonationBox from '../flexibleContent/DonationBox';
import fade from '../../images/fade.png';
import { breakpoints, colors } from '../../styles/utilities/settings';

const DonationBanner = ({ content }) => (
  <SDonationBanner>
    <DonationBox banner content={content} />
  </SDonationBanner>
);

export default DonationBanner;

const SDonationBanner = styled.div`
  padding: 100px 0;

  @media screen and (min-width: ${breakpoints.ipadPort}px) {
    background-image: url(${fade});
    background-position: top center;
    background-repeat: no-repeat;
    background-size: 100%;
  }

  @media screen and (max-width: ${breakpoints.ipadPort - 1}px) {
    padding: 50px 0;
    background: ${colors.blueGradientBanner};
  }
`;
