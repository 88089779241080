import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Wrapper from '../../styles/utilities/Wrapper';
import HeadingContent, {
  HeadingContainer,
} from '../reusableObjects/HeadingContent';
import ImageLoader from '../helpers/ImageLoader';
import { breakpoints, colors } from '../../styles/utilities/settings';
import IconRow from '../../images/iconRow.svg';

const StandoutHero = ({ content: { headingContent, imageFeature } }) => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setLoaded(true);
  }, []);
  return (
    <SStandoutHero loaded={loaded}>
      <div className="row" />
      <div className="row" />
      <Wrapper>
        <div className="inner">
          <HeadingContent hero content={headingContent} />
          <div className="image">
            <ImageLoader src={imageFeature} width={400} />
          </div>
        </div>
      </Wrapper>
    </SStandoutHero>
  );
};

export default StandoutHero;

const SStandoutHero = styled.div`
  @keyframes scroll {
    100% {
      background-position: 588px 0;
    }
  }

  @keyframes revScroll {
    100% {
      background-position: -588px 0;
    }
  }

  overflow: hidden;
  position: relative;
  background: ${colors.blueGradientBanner};

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 200px;
    width: 100%;
    z-index: 1;
    background: ${colors.whiteGradient};
  }

  .row {
    position: absolute;
    background-image: url(${IconRow});
    left: 50%;
    transform: translateX(-50%);
    height: 70px;
    width: 100%;

    &:nth-child(1) {
      animation: scroll 30s linear infinite;
      top: 15%;
    }

    &:nth-child(2) {
      bottom: 15%;
      animation: revScroll 30s linear infinite;
    }
  }

  ${Wrapper} {
    @media screen and (min-width: ${breakpoints.ipadPort}px) {
      display: flex;
      height: 100%;
      align-items: flex-end;
      justify-content: space-between;
    }

    @media screen and (max-width: ${breakpoints.ipadPort - 1}px) {
      padding: 0 30px;
    }

    > * {
      @media screen and (min-width: ${breakpoints.ipadPort}px) {
        width: 50%;
      }
    }
  }

  .inner {
    @media screen and (min-width: ${breakpoints.ipadPort}px) {
      display: flex;
      align-items: center;
      width: 100%;
    }

    > div {
      @media screen and (min-width: ${breakpoints.ipadPort}px) {
        width: 50%;
      }
    }
  }

  .image {
    margin: 0 auto;

    @media screen and (min-width: ${breakpoints.ipadPort}px) {
      max-width: ${breakpoints.pageWidth / 2.5}px;
      padding-top: 120px;
      position: relative;
      top: ${({ loaded }) => (loaded ? '0' : '500px')};
      transition-duration: 0.7s;
      transition-timing-function: ease-in-out;
    }

    @media screen and (max-width: ${breakpoints.ipadPort - 1}px) {
      max-width: 75%;
    }
  }

  ${HeadingContainer} {
    @media screen and (max-width: ${breakpoints.mobile - 1}px) {
      max-width: 280px;
      margin: 0 auto;
    }
    h1 {
      color: ${colors.blue};
      line-height: 1.2;

      @media screen and (min-width: ${breakpoints.pageWidth}px) {
        font-size: 60px;
      }

      @media screen and (max-width: ${breakpoints.ipadPort - 1}px) {
        padding: 50px 0 0;
        text-align: center;
      }
    }

    p {
      color: ${colors.blue};
      font-size: 25px;
      margin-bottom: 0;

      @media screen and (max-width: ${breakpoints.ipadPort - 1}px) {
        text-align: center;
        font-weight: bold;
        line-height: 1.2;
        margin-bottom: 30px;
      }
    }
  }
`;
