import React from 'react';
import styled from 'styled-components';
import Wrapper from '../../styles/utilities/Wrapper';
import Video from '../helpers/Video';
import HeadingContent, {
  HeadingContainer,
} from '../reusableObjects/HeadingContent';
import { colors, breakpoints } from '../../styles/utilities/settings';
import BackgroundImage, { SBackgroundImage } from '../helpers/BackgroundImage';
import { SImageLoader } from '../helpers/ImageLoader';

const HeroBanner = ({ mainImage, content, content: { headingContent } }) => (
  <SHeroBanner>
    <BackgroundImage src={mainImage} alt={headingContent.heading}>
      {content.video && <Video src={content.video} />}
      <Wrapper>
        <HeadingContent hero content={headingContent} />
      </Wrapper>
    </BackgroundImage>
  </SHeroBanner>
);

export default HeroBanner;

export const SHeroBanner = styled.div`
  ${SBackgroundImage} {
    @media screen and (min-width: ${breakpoints.ipadPort}px) {
      color: ${colors.white};
      padding: 100px 0;
    }

    @media screen and (min-width: ${breakpoints.ipadLand}px) {
      padding: 200px 0;
    }
  }

  ${Wrapper} {
    max-width: ${breakpoints.pageWidth + 400}px;
  }

  ${HeadingContainer} {
    @media screen and (min-width: ${breakpoints.ipadPort}px) {
      max-width: 40%;
    }

    p {
      font-weight: 600;
    }
  }

  ${SImageLoader} {
    @media screen and (max-width: ${breakpoints.ipadPort - 1}px) {
      position: relative !important;
      top: 0;
      left: 0;
      width: 100%;
      height: auto !important;
      transform: none;
      min-width: auto;
      min-height: auto;
      padding: 0 !important;

      img {
        position: relative;
      }
    }
  }
`;
